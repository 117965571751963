<template>
  <div>
    <vs-row vs-justify="center">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <vs-card>
          <div slot="header">
            <h3>Futurense Login Creds</h3>
          </div>
          <vs-row style="margin-top: 1%">
            <vs-col vs-w="3">First name : </vs-col>
            <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="9">
              <vs-input placeholder="First name" v-model="first_name" />
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 1%">
            <vs-col vs-w="3">Last Name : </vs-col>
            <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="9">
              <vs-input placeholder="Last Name" v-model="last_name" />
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 1%">
            <vs-col vs-w="3">Email : </vs-col>
            <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="9">
              <vs-input placeholder="Email" v-model="email" />
            </vs-col>
          </vs-row>
          <vs-row vs-justify="flex-end" style="margin-top: 1%">
            <vs-button color="primary" type="filled" @click="SubmitCreds()"
              >Submit
              </vs-button
            >
          </vs-row>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import constants from "../../constants.json";
import axios from "axios";
export default {
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
    };
  },
  methods: {
    SubmitCreds() {
      console.log(
        "submit details",
        this.first_name,
        this.last_name,
        this.email
      );
      let obj = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
      };
      const url = `${constants.MILES_CM_BACK}createFuturenseUser`;
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          if(response.data.status == 'success'){
            this.$vs.notify({
              title: response.data.message,
              color: "success",
              fixed:true,
            });
          }
          if(response.data.status == 'error'){
            this.$vs.notify({
              title: response.data.message,
              color: "danger",
              fixed:true,
            });
          }
        });
    },
  },
};
</script>

<style>
</style>